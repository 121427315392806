import React from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"

import BlueButton from "../buttons/BlueButton"
import GoogleImage from "../GoogleImage"

import { colors, devices } from "../../settings"
import Layout2Columns from "../layout/Layout2Columns";

const LandingPageImage = styled.div`
    margin: 8px;
    padding-top: 13px;
`
const GreyBox = styled.div`
    background-color: ${colors.grey};
    box-sizing: border-box;
    padding: 32px;
    height: 255px;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 15px;
    text-align: center;

    h1 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }

    @media ${devices.tablet} {
        padding: 20px;
        
        h1 {
            margin: 10px;
            font-size: 25px;
        }
    }  
`

function renderLandingPages(pages, start, end, linkClicked) {
    let rows = [];

    for (let i=start; i<=end; i++) {
        let page = pages[i].page;

        rows.push(
            <LandingPageImage key={page.id}>
                <Link to={"/pages/" + page.name + "/"} onClick={linkClicked}>
                    <GoogleImage 
                        root 
                        required
                        lazy
                        src={page.url} 
                        alt={page.title} 
                         
                        style={{
                            width: "230px"
                        }}
                    />
                </Link>
            </LandingPageImage>
        );
    }

    return rows;
}

function TemplatesPanel(props) {
    const data = useStaticQuery(graphql`
        {
            allMysqlFeaturedLandingPages {
                edges {
                    page: node {
                        id
                        name
                        title
		                url
                    }
                }
            }
        }
    `);

    return (
        <Layout2Columns align="top">
            <GreyBox>
                <h1>Great Designs Every Time</h1>
                <div>
                    Our sign templates are easy to customize to fit your business identity.
                </div>
                <br />
                <div>
                    <Link to="/templates/" onClick={props.linkClicked}>
                        <BlueButton label="Explore Templates" />
                    </Link>
                </div> 
            </GreyBox>
            <div>
                <Layout2Columns>
                    <div>
                        { renderLandingPages(data.allMysqlFeaturedLandingPages.edges, 0, 2, props.linkClicked) }
                    </div>
                    <div>
                        { renderLandingPages(data.allMysqlFeaturedLandingPages.edges, 3, 5, props.linkClicked) }
                    </div>
                </Layout2Columns>
            </div>
        </Layout2Columns>
    );
}

export default TemplatesPanel